<template>
    <!-- join bar  各维度具体参与情况 柱状图 -->
    <div class="join-bar">
        <div class="chart-box" v-show="joinBarFlag">
            <div id="joinBar"></div>
        </div>
        <!-- 无内容-->
        <Empty :show="!joinBarFlag"></Empty>
        <!-- <Empty :show="true"></Empty> -->
    </div>

</template>

<script>
    import _this from '../../../main'
    export default {
        props: {
            suitId: {
                type: Number
            },
        },
        data() {
            return {
                joinBarFlag: true,
                // maxValue: 0, //最大值
                // xAxisData: [], //横坐标轴数据
                dimensionsArray: [], //dataset维度信息
                sourceData: [], //dataset原始数据
                seriesConfig: [], //series配置项
            }
        },
        watch: {
            suitId(newVal, oldVal) {
                if (!newVal) return;
                this.getJoinBar();
            }
        },
        methods: {
            // 获取综合个人能力数据数据
            async getJoinBar() {
                let params = {
                    suitId: this.suitId,
                    userId: this.$store.state.user.userInfo.id
                }
                let resData = await this.$Api.DST.getAbilityDimension(params);
                // console.log(resData)
                let data1 = []
                let data2 = []
                this.dimensionsArray = []
                this.sourceData = []
                this.seriesConfig = []
                if (Object.prototype.isPrototypeOf(resData.data)) {
                    this.joinBarFlag = true;
                    Object.keys(resData.data).forEach(key => {
                        let obj = {
                            product: key
                        };
                        if (Array.prototype.isPrototypeOf(resData.data[key]) && resData.data[key]
                            .length > 0) {
                            resData.data[key].forEach((val, index) => {
                                data1.push(val)
                                // console.log(data1)
                                if (key == val.title) {
                                    if (val.dimensionName === '作业') {
                                        obj['作业'] = val.score
                                    }
                                    if (val.dimensionName === '课程') {
                                        obj['课程'] = val.score
                                    }
                                    if (val.dimensionName === '活动') {
                                        obj['活动'] = val.score
                                    }
                                }
                            })
                        }
                        this.sourceData.push(obj);
                    })
                    data1.map(item => {
                        data2.push(item.dimensionName)
                    })

                    data2 = [...new Set(data2)]
                    data2.map(item => {
                        let val = {
                            type: 'bar'
                        }
                        this.dimensionsArray.push(item)

                        this.seriesConfig.push(val)
                        let colorAarry = {
                            0: [{
                                offset: 1,
                                color: '#00C9F8'
                            }, {
                                offset: 0,
                                color: '#009AED'
                            }],
                            1: [{
                                offset: 1,
                                color: '#E96240'
                            }, {
                                offset: 0,
                                color: '#FF8E72'
                            }],
                            2: [{
                                offset: 1,
                                color: '#ffb349'
                            }, {
                                offset: 0,
                                color: '#fb9f1e'
                            }],
                        }
                        this.seriesConfig.forEach((element, index) => {
                            element.barMaxWidth = 15;
                            element.itemStyle = {
                                //柱状颜色
                                normal: {
                                    barBorderRadius: [5, 5, 0, 0],
                                    color: new this.$echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        colorAarry[index]
                                    )
                                }
                            }
                        });
                    })

                    this.dimensionsArray.unshift('product');

                } else {
                    this.joinBarFlag = false;
                }
                this.drawBar();
                // myChartSimple.setOption(myChartSimpleOption(data4, seriesConfig, data3));
            },
            drawBar() {
                let myChartRadar = this.$echarts.init(
                    document.getElementById("joinBar")
                );
                window.addEventListener('resize', () => {
                    myChartRadar.resize()
                })
                myChartRadar.setOption({
                    legend: {
                        top: 10,
                        right: 100
                    },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#999"
                            }
                        }
                    },
                    color: ['#2373EC', '#F96843', '#0DDD0D', '#9073F9'],
                    dataset: {
                        dimensions: this.dimensionsArray,
                        source: this.sourceData,
                        // source: [{product: "准确性维度", 课程: 4}],
                        // [
                        //     ["product", "参与活动", "课程作业", "综合测评", "公开课"],
                        //     ["教学设计能力", 50, 150, 115, 10],
                        //     ["课程资源开发利用能力", 30, 65, 19, 10],
                        //     ["教学示范能力", 35, 110, 5, 10],
                        //     ["教学管理能力", 105, 55, 20, 10],
                        //     ["教学研究创新能力", 10, 30, 19, 5],
                        // ]
                    },
                    xAxis: {
                        type: "category",
                        // 刻度线
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            color: "#333",
                            interval: 0,
                            formatter: function (text) {
                                var strlength = text.length;
                                if (strlength % 6 == 1) {
                                    text = text.replace(/\S{6}/g, function (match) {
                                        // console.log(match);
                                        return match + '\n'
                                    })
                                } else {
                                    text = text.replace(/\S{6}/g, function (match) {
                                        // console.log(match);
                                        return match + '\n'
                                    })
                                    strlength = text.length;
                                    text = text.substring(0, strlength);
                                }
                                return text
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(183, 183, 183, .88)",
                            },
                        },
                        axisTick: {
                            alignWithLabel: true,
                            inside: true,
                            length: 270,
                            lineStyle: {
                                type: 'dotted',
                                color: 'rgba(191, 191, 192, 0.7)'
                            }
                        },
                    },
                    yAxis: {
                        name: "得分",
                        nameTextStyle: {
                            color: "#888888"
                        },
                        axisLabel: {
                            color: "#888888",
                            fontSize: "11"
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(183, 183, 183, .8)",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    series: this.seriesConfig
                    // series: [
                    // {type: "bar", barMaxWidth: 30},
                    // {type: "bar", barMaxWidth: 30,name:'作业'},
                    // {type: "bar", barMaxWidth: 30,name:'活动'},
                    // ]
                })

            }
        },
        mounted() {},
    }
</script>

<style lang="less" scoped>
    .join-bar {
        #joinBar {
            width: 700px;
            height: 400px;
            margin: 0 auto;
            background-color: rgba(35, 115, 236, 0.03);
        }

        @media screen and (min-width: 1250px) {
            #joinBar {
                width: 788px;
            }
        }

    }
</style>