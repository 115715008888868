<template>
  <!-- 能力报告首页 -->
  <div class="center-situation report_composite">
    <div class="top-box wrapper">
      <div class="top-title flex-align-between">
        <div class="select-box">
          <el-select v-model="value" size="mini" @change="changeItem">
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="export-box " @click="$router.push({path:'/report/personal',query:{ suitId: value,breadNumber:4}})">
          <button class="csp">个人能力报告</button>
        </div>
      </div>
      <div class="top-cont">
        <!-- flex-align-between -->
        <div class="two-charts">

          <div class="left-charts">
            <div class="cont-title-box">
              <div class="cont-title">综合个人能力</div>
            </div>
            <Sunburst :suitId="value"></Sunburst>
            <!-- <div v-show="Sunburstflag" id="myChart-Sunburst">
            </div> -->
            <!-- <Empty :show="!Sunburstflag"></Empty> -->
          </div>
          <div class="right-charts">
            <div class="cont-title-box">
              <div class="cont-title">个人能力图谱</div>
            </div>
            <Radar :suitId="value"></Radar>
            <!-- <div v-show="Radarflag" id="myChart-Radar">
            </div>
            <Empty :show="!Radarflag"></Empty> -->
          </div>
        </div>

        <div class="ability-map">
          <div class="ability-analysis" v-if="JSON.stringify(AbilityAnalysisData) !== '{}'">
            <div class="title">能力解析</div>

            <div class="cont">
              通过参与
              <span v-for="(item,index) in AbilityAnalysisData.project" :key="index">
                “{{item}}”
              </span>
              项目，
              共获得<span class="num-bold">{{AbilityAnalysisData.abilityValue || 0}}</span>个能力点，成功开启了<span
                class="num-bold">{{AbilityAnalysisData.firstAbilityCount || 0}}</span>项核心技能
            </div>
          </div>


          <div class="cont-title-box">
            <div class="cont-title">各维度能力水平</div>
          </div>
          <AvgBar :suitId="value"></AvgBar>
          <!-- <div id="myChartAbilityAvg" v-show="AbilityAvgflag">
          </div>
          <Empty :show="!AbilityAvgflag"></Empty> -->

          <div class="cont-title-box">
            <div class="cont-title">各维度具体参与情况</div>
          </div>
          <JoinBar :suitId="value"></JoinBar>
          <!-- <div v-show="Simpleflag" id="myChart-Simple">
          </div>
          <Empty :show="!Simpleflag"></Empty> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import Sunburst from "./component/pa-sunburst.vue";
  import Radar from "./component/alibity-radar.vue";
  import AvgBar from "./component/ability-bar.vue";
  import JoinBar from "./component/join-bar.vue";
  import {
    // myChartSunburstOption,
    // myChartRadarOption,
    // myChartSimpleOption,
    // myChartAbilityAvgOption,


  } from "@/common/options";
  export default {
    data() {
      return {
        options: [],
        value: 0,
        suitId: '',
        AbilityAnalysisData: {},
        // Radarflag: true,
        // Sunburstflag: true,
        // AbilityAvgflag: true,
        // Simpleflag: true,
      };
    },
    components: {
      Sunburst,
      Radar,
      AvgBar,
      JoinBar
    },
    mounted() {
      console.log('vuex中的数据', this.$store.state)
      this.getAbilitySuit();
      this.getMyAbilityAnalysis();
    },
    methods: {
      async getAbilitySuit() {
        let pamars = {}
        let resData = await this.$Api.DST.getAbilitySuit(pamars)
        this.options = resData.data;
        this.value = this.options[0].id;
        this.drawLine();
      },
      changeItem(value) {
        this.value = value;
        // this.drawLine();
      },
      // 获取能力解析数据
      async getMyAbilityAnalysis() {
        let params = {
          // userId: JSON.parse(sessionStorage.getItem('userinfo')).id
          userId: this.$store.state.user.userInfo.id
        }
        let resData = await this.$Api.DST.getMyAbilityAnalysis(params);
        // console.log(resData);
        this.AbilityAnalysisData = resData.data;
      },

      async drawLine() {
        // 综合个人能力
        // let myChartSunburst = this.$echarts.init(
        //   document.getElementById("myChart-Sunburst")
        // );
        // let pamars = {
        //   suitId: this.value
        // }
        // let colorMap = [
        //   {color:'#5470c6',childColor:'#f99e1c'},
        //   {color:'#91cc75',childColor:'#ef5a78'},
        //   {color:'#fac858',childColor:'#f7f1bd'},
        //   {color:'#ee6666',childColor:'#3e0317'},
        //   {color:'#73c0de',childColor:'#e62969'},
        //   {color:'#3ba272',childColor:'#6569b0'},
        //   {color:'#fc8452',childColor:'#ef2d36'},
        //   {color:'#9a60b4',childColor:'#a5446f'},
        //   {color:'#ea7ccc',childColor:'#b53b54'},
        // ]
        // let resDatas = await this.$Api.DST.userFristAbility(pamars);
        // if (Array.prototype.isPrototypeOf(resDatas) && resDatas.length > 0) {
        //   this.Sunburstflag = true;
        //   resDatas.map((item,index) => {
        //     item.childList.map((resitem) => {
        //       resitem.name = `${resitem.name} ${resitem.value}`
        //       resitem.itemStyle =  {
        //         color: colorMap[index].childColor
        //       }
        //     })
        //     item.children = item.childList;
        //   })
        //   console.log(resDatas);
        //   myChartSunburst.setOption(myChartSunburstOption(resDatas));
        // } else {
        //   this.Sunburstflag = false;
        // }


        // 个人能力图谱
        // let myChartRadar = this.$echarts.init(
        //   document.getElementById("myChart-Radar")
        // );

        // let resData = await this.$Api.DST.userSecendAbility(pamars)

        // let myChartRadardata = [];
        // let myChartRadardatavalue = [];
        // if (Array.prototype.isPrototypeOf(resData) && resData.length > 0) {
        //   this.Radarflag = true;
        //   const maxData = Math.max.apply(Math, resData.map(function (item) {
        //     return item.value << 0
        //   }));
        //   resData.map(item => {
        //     let data = {
        //       name: item.name,
        //       max: maxData + 5,
        //     }
        //     myChartRadardatavalue.push(item.value);
        //     myChartRadardata.push(data);
        //   })
        //   myChartRadar.setOption(myChartRadarOption(myChartRadardata, myChartRadardatavalue));
        // } else {
        //   this.Radarflag = false;
        // }




        // let userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
        // let pamarss = {
        //   suitId: this.value,
        //   userId: userInfo.id
        //   // suitId:23,
        //   // userId:50148
        // }
        //各维度能力水平
        // let myChartAbilityAvg = this.$echarts.init(
        //   document.getElementById("myChartAbilityAvg")
        // );

        // let resDatass = await this.$Api.DST.getUserAbilityAvg(pamarss);
        // // console.log(resDatass);
        // let myChartAbilityAvgdata = [];
        // let myScorevalue = [];
        // let mostScorevalue = [];
        // let avgScorevalue = [];
        // if (Array.prototype.isPrototypeOf(resDatass.data) && resDatass.data.length > 0) {
        //   this.AbilityAvgflag = true;

        //   resDatass.data.map(item => {
        //     myScorevalue.push(item.myScore);
        //     mostScorevalue.push(item.mostScore);
        //     avgScorevalue.push(item.avgScore);
        //     myChartAbilityAvgdata.push(item.tile);
        //   })
        //   let maxArray = [Math.max.apply(null, myScorevalue), Math.max.apply(null, avgScorevalue), Math.max.apply(
        //     null, mostScorevalue)]
        //   let max = Math.max.apply(null, maxArray);
        //   myChartAbilityAvg.setOption(myChartAbilityAvgOption(myChartAbilityAvgdata, myScorevalue, mostScorevalue,
        //     avgScorevalue, max));
        // } else {
        //   this.AbilityAvgflag = false;
        // }

        //能力报告-各维度具体参与情况
        // let myChartSimple = this.$echarts.init(
        //   document.getElementById("myChart-Simple")
        // );
        // myChartSimple.clear();
        // let resDatasss = await this.$Api.DST.getAbilityDimension(pamarss);
        // // console.log(resDatasss)
        // let data1 = []
        // let data2 = []
        // let data3 = []
        // let data4 = []
        // let data5 = []
        // if (Object.prototype.isPrototypeOf(resDatasss.data)) {
        //   this.Simpleflag = true;
        //   // let data3 =[];
        //   Object.keys(resDatasss.data).forEach(key => {
        //     let obj = {
        //       product: key
        //     };
        //     if (Array.prototype.isPrototypeOf(resDatasss.data[key]) && resDatasss.data[key].length > 0) {
        //       resDatasss.data[key].forEach((val, index) => {
        //         data1.push(val)
        //         if (key == val.title) {
        //           // data3.push(val.score)
        //           if (val.dimensionName === '作业') {
        //             obj['作业'] = val.score
        //           }
        //           if (val.dimensionName === '课程') {
        //             obj['课程'] = val.score
        //           }
        //           if (val.dimensionName === '活动') {
        //             obj['活动'] = val.score
        //           }
        //         }
        //       })
        //     }
        //     data4.push(obj);
        //   })
        //   data1.map(item => {
        //     data2.push(item.dimensionName)
        //   })
        //   data2 = [...new Set(data2)]
        //   data2.map(item => {
        //     let val = {
        //       type: 'bar'
        //     }
        //     data3.push(item)

        //     data5.push(val)
        //     let colorAarry = {
        //       0: [{
        //         offset: 1,
        //         color: '#00C9F8'
        //       }, {
        //         offset: 0,
        //         color: '#009AED'
        //       }],
        //       1: [{
        //         offset: 1,
        //         color: '#E96240'
        //       }, {
        //         offset: 0,
        //         color: '#FF8E72'
        //       }],
        //       2: [{
        //         offset: 1,
        //         color: '#ffb349'
        //       }, {
        //         offset: 0,
        //         color: '#fb9f1e'
        //       }],
        //     }
        //     data5.forEach((element, index) => {
        //       element.barMaxWidth = 15;
        //       element.itemStyle = {
        //         //柱状颜色
        //         normal: {
        //           barBorderRadius: [5, 5, 0, 0],
        //           color: new this.$echarts.graphic.LinearGradient(
        //             0, 0, 0, 1,
        //             colorAarry[index]
        //           )
        //         }
        //       }
        //     });
        //   })

        //   data3.unshift('product');

        // } else {
        //   this.Simpleflag = false;
        // }

        // console.log(data4)
        // console.log(data5)
        // console.log(data3)
        // myChartSimple.setOption(myChartSimpleOption(data4, data5, data3));

        // 图表浏览器自适应
        setTimeout(function () {
          window.onresize = function () {
            // 综合个人能力
            // myChartSunburst.resize();
            // 个人能力图谱
            // myChartRadar.resize();
            // myChartAbilityAvg.resize();
            // myChartSimple.resize();
          };
        }, 200);
      },
    },

  };
</script>

<style lang="less" scoped>
  .report_composite {

    .top-box {
      padding: 32px 40px 0 40px;

      @media screen and (min-width:1250px) {
        padding: 32px 76px 0 91px;
      }

      .top-title {
        .select-box {
          /deep/ .el-input__inner {
            border-radius: 25px;
          }
        }

        .export-box {
          cursor: pointer;

          button {
            font-size: 13px;
            padding: 0 15px;
            line-height: 29px;
            color: #fff;
            background-color: #508EF9;
            border-radius: 25px;
          }
        }
      }

      .top-cont {
        margin: 34px auto 0;
        padding-bottom: 50px;

        .cont-title-box {
          display: flex;

          .cont-title {
            flex: 1;
            font-weight: 600;
            font-size: 14px;
            line-height: 60px;

            &::before {
              content: ' ';
              display: inline-block;
              width: 3px;
              height: 15px;
              background-color: #508EF9;
              vertical-align: -2px;
              margin-right: 9px;
              border-radius: 2px;
            }
          }
        }

        // 能力解析
        .ability-analysis {
          margin: 50px auto 0;
          background: rgba(36, 116, 236, 0.03);
          padding: 25px 35px;

          .title {
            font-weight: 600;

            &::before {
              content: ' ';
              display: inline-block;
              width: 3px;
              height: 15px;
              background-color: #508EF9;
              vertical-align: -2px;
              margin-right: 9px;
              border-radius: 2px;
            }
          }

          .cont {
            margin-top: 10px;
          }

          .font-bold {
            font-size: 18px;
            font-weight: bold;
          }

          .num-bold {
            padding: 0 5px;
            font-size: 16px;
            font-weight: bold;
            color: #508EF9;
          }
        }

        // 个人能力图谱 
        // #myChart-Sunburst,
        // #myChart-Radar {
        //   width: 500px;
        //   height: 500px;
        //   margin: 0 auto;
        // }

        // 各维度能力水平
        #myChartAbilityAvg,
        #myChart-Simple {
          // width: 753px;
          height: 400px;
          margin: 0 auto;
          background-color: rgba(35, 115, 236, 0.03);
        }
      }
    }
  }
</style>