<template>
    <!-- ability bar  各维度能力水平 柱状图 -->
    <div class="ability-bar">
        <div class="chart-box" v-show="abilityBarFlag">
            <div id="avgBar"></div>
        </div>
        <!-- 无内容-->
        <Empty :show="!abilityBarFlag"></Empty>
        <!-- <Empty :show="true"></Empty> -->
    </div>

</template>

<script>
    import _this from '../../../main'
    export default {
        props: {
            suitId: {
                type: Number
            },
        },
        data() {
            return {
                abilityBarFlag: true,
                maxValue: 0, //最大值
                xAxisData: [], //横坐标轴数据
                myAbilityValue: [], //个人能力分数
                highestValue: [], //最高分
                avgValue: [], //平均分
            }
        },
        watch: {
            suitId(newVal, oldVal) {
                if (!newVal) return;
                this.getAbilityBar();
            }
        },
        methods: {
            // 获取综合个人能力数据数据
            async getAbilityBar() {
                let params = {
                    suitId: this.suitId,
                    userId: this.$store.state.user.userInfo.id
                }
                let resData = await this.$Api.DST.getUserAbilityAvg(params);
                // console.log(resData);
                this.xAxisData = [];
                this.myAbilityValue = [];
                this.highestValue = [];
                this.avgValue = [];
                if (Array.prototype.isPrototypeOf(resData.data) && resData.data.length > 0) {
                    this.abilityBarFlag = true;

                    resData.data.map(item => {
                        this.myAbilityValue.push(item.myScore);
                        this.highestValue.push(item.mostScore);
                        this.avgValue.push(item.avgScore);
                        this.xAxisData.push(item.tile);
                    })
                    let maxArray = [Math.max.apply(null, this.myAbilityValue),
                        Math.max.apply(null, this.avgValue),
                        Math.max.apply(null, this.highestValue)
                    ]
                    this.maxValue = Math.max.apply(null, maxArray);
                    this.drawBar();
                } else {
                    this.abilityBarFlag = false;
                }
            },
            drawBar() {
                let myChartRadar = this.$echarts.init(
                    document.getElementById("avgBar")
                );
                window.addEventListener('resize', () => {
                    myChartRadar.resize()
                })
                myChartRadar.setOption({
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#999"
                            }
                        }
                    },
                    legend: {
                        data: ["最高分", "个人能力", "平均分"],
                        top: 10,
                        right: 100,
                        itemHeight: 12,
                    },
                    xAxis: [{
                        type: "category",
                        // 强制全部显示
                        axisLabel: {
                            color: "#333",
                            interval: 0,
                            formatter: function (text) {
                                var strlength = text.length;
                                if (strlength % 6 == 1) {
                                    text = text.replace(/\S{6}/g, function (match) {
                                        // console.log(match);
                                        return match + '\n'
                                    })
                                } else {
                                    text = text.replace(/\S{6}/g, function (match) {
                                        // console.log(match);
                                        return match + '\n'
                                    })
                                    strlength = text.length;
                                    text = text.substring(0, strlength);
                                }
                                return text
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(183, 183, 183, .88)",
                            },
                        },
                        // 刻度线
                        axisTick: {
                            alignWithLabel: true,
                            inside: true,
                            length: 270,
                            lineStyle: {
                                type: 'dotted',
                                color: 'rgba(191, 191, 192, 0.7)'
                            }
                        },
                        data: this.xAxisData,
                        axisPointer: {
                            type: "shadow"
                        }
                    }],
                    yAxis: [{
                            type: "value",
                            name: "最高分",
                            // min: 0,
                            max: this.maxValue,
                            nameTextStyle: {
                                color: "#888888"
                            },
                            interval: 500,
                            axisLabel: {
                                formatter: "{value}",
                                color: "#888888",
                                fontSize: "11"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "rgba(183, 183, 183, .8)",
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false
                            }
                        },
                        { // 
                            type: "value",
                            name: "个人能力",
                            nameTextStyle: {
                                color: "#888888"
                            },
                            // min: 0,
                            max: this.maxValue,
                            interval: 100,
                            axisLabel: {
                                show: false
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "rgba(183, 183, 183, .88)",
                                },
                            },
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                alignWithLabel: false,
                                show: false,
                            },
                        },
                        { // 
                            type: "value",
                            name: "平均分",
                            // min: 0,
                            max: this.maxValue,
                            show: false,
                            interval: 100,
                            offset: 55,
                        }
                    ],
                    series: [{
                            name: "个人能力",
                            type: "bar",
                            data: this.myAbilityValue,
                            barMaxWidth: 15,
                            yAxisIndex: 0,
                            itemStyle: {
                                //柱状颜色
                                normal: {
                                    barBorderRadius: [5, 5, 0, 0],
                                    color: new _this.$echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [{
                                                offset: 1,
                                                color: '#00C9F8'
                                            },
                                            {
                                                offset: 0,
                                                color: '#009AED'
                                            }

                                        ]
                                    )
                                }
                            },

                        },
                        {
                            name: "最高分",
                            type: "bar",
                            data: this.highestValue,
                            barMaxWidth: 15,
                            barGap: '80%',
                            yAxisIndex: 1,
                            itemStyle: {
                                //柱状颜色
                                normal: {
                                    barBorderRadius: [5, 5, 0, 0],
                                    color: new _this.$echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [{
                                                offset: 1,
                                                color: '#E96240'
                                            },
                                            {
                                                offset: 0,
                                                color: '#FF8E72'
                                            }

                                        ]
                                    )
                                }
                            },
                        },
                        {
                            name: "平均分",
                            type: "line",

                            data: this.avgValue,
                            symbolSize: 3,
                            itemStyle: {
                                //线颜色
                                color: "#70A7FF",
                            },
                        }
                    ]
                })

            }
        },
        mounted() {},
    }
</script>

<style lang="less" scoped>
    .ability-bar {
        #avgBar {
            width: 700px;
            height: 400px;
            margin: 0 auto;
            background-color: rgba(35, 115, 236, 0.03);
        }

        @media screen and (min-width: 1250px) {
            #avgBar {
                width: 788px;
            }
        }

    }
</style>